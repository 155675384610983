<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">DAFTAR KOMPETENSI DOKTER</p>
          <router-link :to="{ name: 'kd-add' }" class="card-header-icon has-text-light">
            <b-icon class="has-text-light" size="is-small" icon="plus" />
          </router-link>
        </header>
        <div class="card-content table-card-content">
          <b-field position="is-right" class="filter-field" grouped group-multiline>
            <generic-filter-select
              class="filter3" 
              apiPath="/programstudi/angkatan/options/"
              field="angkatan"
              v-model="filterMap.angkatan"
            ></generic-filter-select>
            
            <generic-filter-select
              class="filter3"
              apiPath="/matakuliah/options/"
              field="Mata Kuliah"
              v-model="filterMap.mata_kuliah"
            ></generic-filter-select>
            <cari-input class="filter3" v-model="filterMap.cari"></cari-input>
            <filter-button @apply-filter="applyFilter"></filter-button>
          </b-field>

          <b-table
            bordered
            striped
            hoverable
            :loading="loading"
            :data="kdList"
            :mobile-cards="false"
          >
            <b-table-column v-slot="props" field="no" label="No">{{ pagination.startRowNum + props.index }}</b-table-column>
            <b-table-column v-slot="props" field="angkatan" label="Angkatan">{{ props.row.angkatan }}</b-table-column>
            <b-table-column v-slot="props" field="modul" label="Kompetensi">
              {{ props.row.kuantitatif }}
              <br />
              <small>{{ props.row.kualitatif }}</small>
            </b-table-column>
            <b-table-column v-slot="props" field="target" label="Target">
              {{ props.row.target }}
            </b-table-column>
            <b-table-column v-slot="props" field="aksi" label="Aksi">
              <router-link :to="{ name: 'kd-change', params: { id: props.row.id } }">
                <b-icon size="is-small" icon="edit" />
              </router-link>
            </b-table-column>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>Tidak ada data.</p>
                </div>
              </section>
            </template>
          </b-table>

          <b-pagination
            v-if="pagination.showPagination"
            :total="pagination.total"
            :per-page="pagination.limit"
            :current.sync="pagination.page"
            order="is-centered"
            icon-pack="fas"
            @change="pageChange"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchActivatedMixin from "@/apps/core/mixins/fetchActivatedMixin";
import paginationMixin from "@/apps/core/mixins/paginationMixin";
import TargetKDList from "../models/targetKDList.js";

export default {
  name: "TargetKdList",
  components: {
    CariInput: () => import("@/apps/core/components/CariInput.vue"),
    FilterButton: () => import("@/apps/core/components/FilterButton.vue"),
    GenericFilterSelect: () =>
      import("@/apps/core/components/GenericFilterSelect.vue")
  },
  data() {
    this.targetKDListMdl = new TargetKDList();
    this.listMdl = this.targetKDListMdl; // bikin alias
    return this.targetKDListMdl.getObservables();
  },
  methods: {
    fetchData() {
      this.targetKDListMdl.load();
    }
  },
  mixins: [onlineCheckMixin, fetchActivatedMixin, paginationMixin]
};
</script>

<style scoped lang="scss">
@import "@/apps/core/assets/scss/listview.scss";
</style>
