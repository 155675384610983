import ListModelBase from "@/apps/core/models/listModelBase.js";

const modelName = "kdList";
const modelPath = "/programstudi/targetkompetensi/";

class TargetKDList extends ListModelBase {
  constructor() {
    let filterMap = {
      angkatan: null,
    }
    super(modelName, modelPath, filterMap);
  }
}

export default TargetKDList;